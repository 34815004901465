<template>
  <div>
    <div class="t-list">
      <div class="t-top">
        <div class="t-search">
          <el-form ref="form" :model="formData" :rules="rules" :validate-on-rule-change="false" @submit.native.prevent>
            <!-- 考试名称 -->
            <el-form-item prop="exam_id">
              <div class="t-input">
                <el-select
                    size="small"
                    v-model="formData.exam_id"
                    :placeholder="'选择考试名称'"
                    :popper-append-to-body="false"
                    @enter="onSearch"
                    @change="examChange">
                  <el-option
                      v-for="item in optionsConfig.examOptions"
                      :label="item.exam_name"
                      :value="item.exam_id"
                  ></el-option>
                </el-select>
              </div>
            </el-form-item>

            <!-- 筛选学校 -->
            <el-form-item prop="school_id">
              <div class="t-input">
                <el-select
                    size="small"
                    v-model="formData.school_id"
                    :placeholder="'筛选学校'"
                    :popper-append-to-body="false"
                    @enter="onSearch"
                    @change="schoolChange($event,'school')">
                  <template v-if="optionsConfig.schoolOptions.length">
                    <el-option label="全部选择" value="0"></el-option>
                  </template>
                  <el-option
                      v-for="item in optionsConfig.schoolOptions"
                      :label="item.school_name"
                      :value="item.school_id"
                  ></el-option>
                </el-select>
              </div>
            </el-form-item>

            <!-- 筛选年级 -->
            <el-form-item prop="grade_id">
              <div class="t-input">
                <el-select
                    v-model="formData.grade_id"
                    size="small"
                    :placeholder="'筛选年级'"
                    :popper-append-to-body="false"
                    @enter="onSearch"
                    @change="schoolChange($event,'grade')">
                  <el-option
                      v-for="item in optionsConfig.gradeOptions"
                      :label="item['grade_name']"
                      :value="item['grade_id']"
                  ></el-option>
                </el-select>
              </div>
            </el-form-item>

            <!-- 筛选教学班 -->
            <el-form-item prop="class_id">
              <div class="t-input">
                <el-select
                    size="small"
                    v-model="formData.class_id"
                    :placeholder="'筛选班级'"
                    @enter="onSearch"
                    :popper-append-to-body="false">
                  <template v-if="optionsConfig.teachingOptions.length">
                    <el-option label="全部选择" value="0"></el-option>
                  </template>
                  <el-option
                      v-for="item in optionsConfig.teachingOptions"
                      :label="item['class_name']"
                      :value="item['id']"
                  ></el-option>
                </el-select>
              </div>
            </el-form-item>

            <el-form-item style="width: 156rem;min-width: 156rem;margin-right: 0">
              <el-button debounce size="small" type="primary" @click="onSearch">搜索</el-button>
              <el-button size="small" style="margin-left: 16rem" @click=" onReset(); ">重置
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <div v-if="isFirst" style="background-color: #f7f7f7;padding-top: 12rem">
      <div class="main-box" v-loading="loading">
        <div class="grade-type">
          <span :class="!formData.subject_id ? 'active' : ''" @click="subjectChange(0)">总成绩</span>
          <span
              v-for="item in optionsConfig.subjectOptions"
              :class="item.id === formData.subject_id ? 'active' : ''"
              @click="subjectChange(item.id)"
          >{{ item.subject_name }}</span>
        </div>
        <div class="card-list">
          <template v-for="item in dataCont">
            <div class="card-box">
              <div v-for="citem in item[0]" class="card-box-box">
                <p class="card-title">{{ citem.name }}</p>
                <div v-if="citem.class_name" class="flex flex-between">
                  <div class="card-list-number">{{ citem.score }}</div>
                  <div class="card-box-right flex flex-column flex-center">
                    <p>{{ citem.class_name }}</p>
                    <p style="margin-top: 12rem">{{ citem.student_name }}</p>
                  </div>
                </div>
                <div v-else
                     class="flex flex-center flex-align"
                     style="width: 80%;height: 60rem;margin: 0 auto">
                  暂无数据
                </div>
              </div>
            </div>
            <div class="card-box">
              <div v-for="citem in item[1]" class="card-box-box">
                <p class="card-title">{{ citem.name }}</p>
                <div v-if="citem.score" style="display: flex;justify-content: center;height: 60rem">
                  <div class="card-list-number">{{ citem.score }}</div>
                </div>
                <div v-else
                     class="flex flex-center flex-align"
                     style="width: 80%;height: 60rem;margin: 0 auto">
                  暂无数据
                </div>
              </div>
            </div>
          </template>
        </div>

        <div style="margin-top: 42rem;display: flex;justify-content: space-between;" v-if="status">
          <l-table
              title-bg="#fafbff"
              class="data-list1"
              :config="config1"
              :table-data="table1"
              total-color="#F6AD3C"
              title="进步学生名单"
              :total="total[0]"
              style="width: 48%;min-width: 528rem;"
              @upPage="changePage(0,0)"
              @downPage="changePage(0,1)"
          ></l-table>
          <l-table
              title="退步学生名单"
              title-bg="#fafbff"
              total-color="#F6AD3C"
              class="data-list2"
              :config="config2"
              :table-data="table2"
              :total="total[1]"
              style="width: 48%;min-width: 528rem;"
              @upPage="changePage(1,0)"
              @downPage="changePage(1,1)"
          ></l-table>
        </div>
      </div>


    </div>

    <t-result v-else type="empty"></t-result>
  </div>
</template>

<script>

export default {
	_config:{"route":{"path":"details","meta":{"title":"数据展示"}}},
  data() {
    return {
      // mock_url: 'https://mock.mengxuegu.com/mock/622089d4e7ee771c8c6e2148/hh',
      mock_url: '',
      isFirst: false,
      loading: false,

      optionsConfig: {
        examOptions: [],    //考试选择配置
        schoolOptions: [],    //筛选学校配置
        gradeOptions: [],    //筛选年级配置
        subjectOptions: [],    //筛选科目配置
        teachingOptions: [],    //筛选教学班配置
      },

      configDataByGet: '',

      formData: {
        exam_id: '',
        school_id: '',
        grade_id: '',
        subject_id: 0,
        class_id: '',
      },

      rules: {
        exam_id: [
          {required: true, message: '请选择考试名称', trigger: 'change'},
        ],
        school_id: [
          {required: true, message: '请筛选学校', trigger: 'change'},
        ],
        grade_id: [
          {required: true, message: '请筛选年级', trigger: 'change'},
        ],
        class_id: [
          {required: true, message: '请筛选班级', trigger: 'change'},
        ],
      },

      dataCont: [],

      imgUrl: require('@/assets/img/no-data.png'),

      config1: [
        {
          prop: 'student_name',
          name: '学生姓名',
        },
        {
          prop: 'score',
          name: '分数',
        },
        {
          prop: 'rank_num',
          name: '总成绩排名',
        },
        {
          prop: 'improve_num',
          name: '距上次进步名次',
        },
      ],
      config2: [
        {
          prop: 'student_name',
          name: '学生姓名',
        },
        {
          prop: 'score',
          name: '分数',
        },
        {
          prop: 'rank_num',
          name: '总成绩排名',
        },
        {
          prop: 'improve_num',
          name: '距上次退步名次',
        },
      ],
      table1: [],
      table2: [],
      // 两个表格的页码
      pages: [1, 1],
      // 数据量
      total: [0, 0],
      parmsOne: {},
      parmsTwo: {},

      status: false,
    };
  },
  created() {
    this.getSearchConfig();
  },
  methods: {
    //获取查询条件
    getSearchConfig() {
      this.$_axios.post(this.mock_url + '/site/get-query-cond').then(res => {
        let data = res.data.data;

        if (data.length === 0) return;
        this.configDataByGet = data;
        data.forEach(item => {
          this.optionsConfig.examOptions.push(item);
        });
      });
    },

    //选择考试名称后触发事件,e为考试id
    examChange(e) {
      this.optionsConfig.schoolOptions = [];
      this.optionsConfig.gradeOptions = [];
      this.optionsConfig.teachingOptions = [];
      this.formData.school_id = '';
      this.formData.grade_id = '';
      this.formData.class_id = '';
      this.formData.subject_id = 0;

      this.$_axios.get(this.mock_url + '/site/exam-subject', {params: {exam_id: e, subject: 1}}).then(res => {
        this.optionsConfig.subjectOptions = JSON.parse(JSON.stringify(res.data.data).replace(/child/g, 'children'));
      });

      let data = this.configDataByGet;
      for (let i = 0; i < data.length; i++) {
        if (e == data[i].exam_id) {
          this.optionsConfig.schoolOptions = data[i].school_data;
          this.optionsConfig.gradeOptions = data[i].grade_data;
          return;
        }
      }
    },

    //根据学校id查询班级配置
    schoolChange(e, str) {
      this.formData.class_id = '';
      if (str === 'school') this.formData.grade_id = '';
      let params = {
        school: Number(this.formData.school_id),
        exam: Number(this.formData.exam_id)
      }
      if (str === 'grade') params.grade = Number(this.formData.grade_id)
      this.$_axios.get(this.mock_url + '/site/role-class', {params}).then(res => {
        let data = res.data.data
        if (data.length) {
          this.optionsConfig.teachingOptions = data
        } else {
          this.optionsConfig.teachingOptions = []
        }
      })
    },

    //科目选择
    subjectChange(e) {
      if (this.formData.school_id !== '' && this.formData.grade_id !== '' && this.formData.class_id !== '') {
        this.formData.subject_id = e;
        this.onSearch();
      }
      if (e !== 0) {
        this.config1[2].name = '科目年级排名';
        this.config2[2].name = '科目年级排名';
      } else {
        this.config1[2].name = '总成绩排名';
        this.config2[2].name = '总成绩排名';
      }
    },

    onSearch() {
      this.pages = [1, 1];
      let params = {...this.formData};
      params.school_id = Number(params.school_id);

      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.isFirst = true;
          this.$_axios.get(this.mock_url + '/exam-achievement', {params}).then(res => {
            this.loading = false;
            this.dataCont = [];
            let data = res.data.data;
            if (data.length) {
              let arr = [];
              data.forEach((item, index) => {
                arr.push(item);
                if (index % 2 === 1) {
                  this.dataCont.push(arr);
                  arr = [];
                }
              });
            }
          });

          this.parmsOne = JSON.parse(JSON.stringify(params));
          this.parmsOne.improve = 1;
          this.parmsTwo = JSON.parse(JSON.stringify(params));
          this.parmsTwo.improve = 0;
          this.getTableData1(this.parmsOne);
          this.getTableData2(this.parmsTwo);

        }
      });
    },

    // 获取表格1 的数据
    getTableData1(search) {
      this.$_axios2.get('/api/work/exam-achievement/stu-study-stat', {
        params: {
          ...search,
          page: this.pages[0],
        },
      }).then(res => {
        if (res.data.status === 1001) {
          this.status = false;
        } else {
          this.status = true;
          this.table1 = res.data.data.list;
          this.total[0] = res.data.data.page.total;
        }
      });
    },
    // 获取表格2的数据
    getTableData2(search) {
      this.$_axios2.get('/api/work/exam-achievement/stu-study-stat', {
        params: {
          ...search,
          page: this.pages[1],
        },
      }).then(res => {
        if (res.data.status !== 1001) {
          this.table2 = res.data.data.list;
          this.total[1] = res.data.data.page.total;
        }
      });
    },

    onReset() {
      this.table1 = [];
      this.table2 = [];
      this.total = [0, 0];

      this.optionsConfig.schoolOptions = [];
      this.optionsConfig.gradeOptions = [];
      this.optionsConfig.teachingOptions = [];
      this.optionsConfig.subjectOptions = [];
      this.formData.subject_id = 0;

      let data = JSON.parse(JSON.stringify(this.dataCont));
      if (data.length) {
        data.forEach(item => {
          item.forEach(citem => {
            Object.keys(citem).forEach(key => {
              if (key !== 'name') {
                citem[key] = '';
              }
            });
          });
        });
      }
      this.dataCont = data;

      this.$refs['form'].resetFields();
    },

    changePage(index, type) {
      let pageSize = Math.ceil(this.total[index] / 10);
      let page = this.pages[index];
      if (type === 0)
        page--;
      else
        page++;
      if (page <= pageSize && page > 0) {
        this.pages[index] = page;
        if (index === 0) {
          this.getTableData1(this.parmsOne);
        } else {
          this.getTableData2(this.parmsTwo);
        }
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.f14 {
  font-size: 14rem;
}

.c3 {
  color: #333;
}

.mr-20 {
  margin-right: 20rem;
}

.grade-type {
  display: flex;
}

.t-list {
  padding: 12rem 0 0 32rem;

  .t-top {
    width: 100%;

    .t-search {
      display: flex;
      line-height: 40rem;
      width: 100%;

      ::v-deep .el-form-item__content,
      ::v-deep .el-input,
      .el-select,
      .t-input {
        width: 100% !important;
      }

      ::v-deep .el-select__tags {
        max-width: none !important;
      }

      .el-form {
        display: flex;
        flex-wrap: nowrap;

        .el-form-item {
          margin-right: 20rem;
          width: calc((100% - 260rem) / 4);
          margin-bottom: 12rem;
        }
      }
    }
  }

}


.main-box {
  background-color: #fff;
  padding: 43rem 69rem 0 65rem;

  .grade-type {
    display: flex;
    flex-wrap: nowrap;
    border-radius: 10rem;
    overflow: hidden;
    margin-bottom: 64rem;

    span {
      cursor: pointer;
      width: 120rem;
      height: 36rem;
      line-height: 36rem;
      color: #666;
      font-size: 14rem;
      text-align: center;
      transition: 0.2s;
      position: relative;
      background-color: #fafafa;

      &:hover {
        background-color: #e9edfe;
      }

      &.active {
        background-color: #1C4EFD;
        color: #fff;

        &:after {
          visibility: hidden;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: -5rem;
        right: 0;
        width: 1rem;
        height: 44rem;
        background-color: #ccc;
        transform: scale(.5);
      }

      &:last-child:after {
        display: none;
      }

      &:last-child {
        border-radius: 0 10rem 10rem 0;
      }
    }
  }
}

.my-search {
  display: flex;
  flex-wrap: wrap;

  .t-input,
  .el-button {
    margin-top: 20rem;
  }

  .el-select {
    margin-right: 20rem;
    min-width: 160rem;
  }

  .el-input {
    width: 90rem;
    margin-right: 20rem;
  }

  span {
    font-size: 13rem;
    color: #333;
    width: 60rem;
    min-width: 60rem;
    line-height: 40rem;
    margin-right: 20rem;
  }
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  //min-width: 1040rem;
  .card-box {
    background-image: url("~@/assets/img/card_bg_blue.png");
    background-size: 100% 100%;
    width: calc((100% - 30rem) / 2);
    min-width: 460rem;
    height: 280rem;
    border-radius: 6rem;
    margin-bottom: 20rem;
    font-size: 14rem;
    color: #fff;
    padding: 38rem 27rem 0;
    display: flex;
    justify-content: space-between;
    position: relative;

    &:nth-child(3),
    &:nth-child(4) {
      background-image: url("~@/assets/img/card_bg_yellow.png");
    }

    &:nth-child(2),
    &:nth-child(4) {
      p {
        text-align: center;
      }

      .card-list-number {
        margin-right: 0;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(.5);
      width: 1rem;
      height: 138%;
      background-color: #fff;
    }

    .card-box-box {
      width: 44%;
    }

    .card-list-number {
      font-size: 68rem;
      display: flex;
      align-items: center;
    }

    .card-title {
      font-size: 16rem;
      margin-bottom: 45rem;
    }

    .card-box-right {
      width: 94rem;
    }
  }
}

@media screen and (max-width: 1800rem) {
  .card-list {
    .card-box {
      height: 255rem;

      .card-list-number {
        font-size: 58rem;
      }
    }
  }
}

@media screen and (max-width: 1680rem) {
  .card-list {
    .card-box {
      .card-list-number {
        font-size: 48rem;
      }
    }
  }
}

@media screen and (max-width: 1580rem) {
  .card-list {
    .card-box {
      height: 185rem;

      .card-list-number {
        font-size: 42rem;
      }

      .card-title {
        margin-bottom: 20rem;
      }
    }
  }
}

@media screen and (max-width: 1480rem) {
  .card-list {
    .card-box {
      height: 160rem;
      padding: 24rem 26rem 0;

      .card-list-number {
        font-size: 28rem;
      }
    }
  }
}

@media screen and (max-width: 1380rem) {
  .main-box {
    padding-left: 20rem;
    padding-right: 20rem;
  }
  .card-list .card-box {
    width: calc((100% - 18rem) / 2);
    margin-bottom: 16rem;
  }
}
</style>
